import React, {useContext, useState} from 'react'

import {ApartmentOutlined} from '@ant-design/icons'
import {Button, Flex, message, Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {nodeDimensions} from 'theme.js'

import WorkflowContext from 'contexts/workflow-context.js'

const {Text} = Typography

const NodeWrapper = styled(Flex)`
  width: ${nodeDimensions.width}px;
  background-color: ${({theme}) => theme.token.contentBgLight};
  padding: ${({theme}) => theme.antd.paddingMD}px;
  border-radius: ${({theme}) => theme.antd.borderRadius}px;
  margin: ${({theme}) => theme.antd.borderRadius}px;
  opacity: 0.9;

  .text {
    text-align: center;
  }
`

function PlaceholderNode() {
  const {t} = useTranslation('translation', {keyPrefix: 'PlaceholderNode'})

  const {workspace} = useContext(WorkflowContext)
  const [messageApi, contextHolder] = message.useMessage()

  const [isLoading, setIsLoading] = useState(false)

  const startWorkspace = async () => {
    setIsLoading(true)
    try {
      await workspace.startWorkspace()
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }

    setIsLoading(false)
  }

  return (
    <NodeWrapper
      vertical
      align='center'
      gap='middle'
    >
      {contextHolder}
      <img src='/images/workflow-illustration.svg' width={150} alt={t('illustrationAlt')}/>
      <Flex vertical align='center' justify='center' gap='middle'>
        <Text italic className='text'>
          {t('emptyWorkflow')}
        </Text>

        {!workspace?.isActive && (
          <Button
            type='primary'
            icon={<ApartmentOutlined/>}
            loading={isLoading}
            onClick={startWorkspace}
          >
            {t('startButtonLabel')}
          </Button>
        )}
      </Flex>
    </NodeWrapper>
  )
}

export default PlaceholderNode
