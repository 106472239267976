import {useContext} from 'react'

import {Breadcrumb, Typography} from 'antd'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {getHomeUrl} from 'helpers/url.js'

import PageContext from 'contexts/page-context.js'

const appName = process.env.REACT_APP_NAME

const itemRender = (route, params, routes) => {
  const isLast = route?.path === routes.at(-1)?.path

  return isLast ? (
    <span>{route.title}</span>
  ) : (
    <Link to={route.path}>{route.title}</Link>
  )
}

const Title = styled(Typography.Text)`
  font-size: ${({theme}) => theme.antd.fontSizeHeading4}px;
  font-weight: ${({theme}) => theme.antd.fontWeightLight};

  @media (max-width: ${({theme}) => theme.antd.screenXSMax}px) {
    font-size: ${({theme}) => theme.antd.fontSizeHeading5}px;
  }

  & a, & a:visited, & a:hover, & a:active, & a:link {
    color: ${({theme}) => theme.antd.colorText};
  }
`

function HeaderNav() {
  const {headerNavItems} = useContext(PageContext)

  if (headerNavItems.length <= 1) {
    return (
      <Title>
        {headerNavItems[0]?.title || (
          <Link to={getHomeUrl()}>
            {appName}
          </Link>
        )}
      </Title>
    )
  }

  return (
    <Breadcrumb
      items={headerNavItems}
      itemRender={itemRender}
    />
  )
}

export default HeaderNav
